import {
    ActivatedRouteSnapshot, Data,
    RouterStateSnapshot
} from '@angular/router';
import { inject } from '@angular/core';
import { ISbComponentType, ISbError, ISbStory } from 'storyblok-js-client';
import { S1Layout } from '@seven1/model';
import { StoryblokService } from '@seven1/angular-storyblok/api';

export interface LayoutData extends Data {
    layout: ISbStory | ISbError;
}

export function layoutResolver<T extends ISbComponentType<string>= S1Layout>(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): Promise<T> {
    const service = inject(StoryblokService);
    let slug: string = service.config.layoutStorySlug;
    if (slug.startsWith('/')) {
        slug = slug.substring(1);
    }

    return new Promise<T>((resolve, reject) => {
        service
            .getStory<T>(slug)
            .then((story) => {
                if (!story) {
                    reject(new Error('Layout not found'));
                    return;
                }
                const layoutConfig: T = story.content;
                resolve(layoutConfig);
            })
            .catch((e) => {
                console.error(
                    'Trying to get 404 story because we could not find ' + slug,
                    e
                );
            });
    });
};
